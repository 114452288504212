import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home as HomeIcon, FlightTakeoff as FlightIcon, EventNote as EventNoteIcon, Pets as PetsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: '2.5rem', // Увеличиваем размер иконок в 2.5 раза для лучшей видимости
  },
}));

const BottomNav: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, paddingTop: '10px', paddingBottom: '10px' }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <StyledBottomNavigationAction 
          label={t('common.home')} 
          icon={<HomeIcon />} 
          onClick={() => navigate('/')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.flights')} 
          icon={<FlightIcon />} 
          onClick={() => navigate('/search')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.bookings')} 
          icon={<EventNoteIcon />} 
          onClick={() => navigate('/bookings')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.pets')} 
          icon={<PetsIcon />} 
          onClick={() => navigate('/pets')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.profile')} 
          icon={<AccountCircleIcon />} 
          onClick={() => navigate('/profile')} 
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
