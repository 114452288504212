import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WhiteCatLogo from "../images/whitecat_stroke.svg";
import { useCurrency, Currency } from '../contexts/CurrencyContext';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: { auth: { user: any } }) => state.auth.user !== null);
  const { currency, setCurrency } = useCurrency();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrencyChange = (newCurrency: Currency) => {
    setCurrency(newCurrency);
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const getCurrencySymbol = (curr: Currency) => {
    switch (curr) {
      case 'EUR': return '€';
      case 'USD': return '$';
      case 'RUB': return '₽';
      default: return curr;
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h5" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <RouterLink to="/" style={{ display: 'flex', alignItems: 'center', fontFamily: '"Nunito", sans-serif', fontWeight: 800, color: "white", textDecoration: "none", marginRight: '10px' }}>
            <img src={WhiteCatLogo} alt="AviaPet Logo" style={{ height: '47px', marginRight: '10px', marginLeft: '5%' }} />
            avia.pet
          </RouterLink>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton 
            onClick={handleClick}
            sx={{ 
              color: 'white', 
              border: '1px solid white', 
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              marginRight: 2
            }}
          >
            {getCurrencySymbol(currency)}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleCurrencyChange('EUR')}>EUR</MenuItem>
            <MenuItem onClick={() => handleCurrencyChange('USD')}>USD</MenuItem>
            <MenuItem onClick={() => handleCurrencyChange('RUB')}>RUB</MenuItem>
          </Menu>
          <IconButton color="inherit" component={RouterLink} to="/search">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <SearchIcon />
              <Typography variant="caption" sx={{ fontFamily: '"Nunito", sans-serif', fontWeight: 600 }}>
                {t("header.search")}
              </Typography>
            </Box>
          </IconButton>
          {isAuthenticated ? (
            <IconButton color="inherit" onClick={handleLogout}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <LoginIcon />
                <Typography variant="caption" sx={{ fontFamily: '"Nunito", sans-serif', fontWeight: 600 }}>
                  {t("header.logout")}
                </Typography>
              </Box>
            </IconButton>
          ) : (
            <>
              <IconButton color="inherit" component={RouterLink} to="/register">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PersonAddIcon />
                  <Typography variant="caption" sx={{ fontFamily: '"Nunito", sans-serif', fontWeight: 600 }}>
                    {t("header.register")}
                  </Typography>
                </Box>
              </IconButton>
              <IconButton color="inherit" component={RouterLink} to="/login">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <LoginIcon />
                  <Typography variant="caption" sx={{ fontFamily: '"Nunito", sans-serif', fontWeight: 600 }}>
                    {t("header.login")}
                  </Typography>
                </Box>
              </IconButton>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
