// frontend/web/src/App.tsx

import React, { lazy, Suspense, useState } from 'react';
import './App.css'; // Добавьте эту строку, если ее нет
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Box, AppBar } from '@mui/material';

import { DeviceContext } from './contexts/DeviceContext';
import { CurrencyProvider } from './contexts/CurrencyContext';

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingFallback from './components/LoadingFallback';
import ErrorBoundary from './components/ErrorBoundary';
import NotificationSystem from './components/NotificationSystem';
import ProtectedRoute from './components/ProtectedRoute';
import BottomNav from './components/BottomNavigation';

import { store } from './store';

const Home = lazy(() => import('./pages/Home'));
const SearchForm = lazy(() => import('./components/SearchForm'));
const FlightListing = lazy(() => import('./components/FlightListing'));
const DetailedFlightView = lazy(() => import('./components/DetailedFlightView'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const UserProfile = lazy(() => import('./components/UserProfile'));
const Landing = lazy(() => import('./components/Landing'));
const SimpleFlightSearch = lazy(() => import('./components/SimpleFlightSearch'));
const Rules = lazy(() => import('./components/Rules'));
const AdaptiveLanding = lazy(() => import('./components/AdaptiveLanding'));
const SerpApiFlightSearch = lazy(() => import('./components/SerpApiFlightSearch'));
const About = lazy(() => import('./pages/About'));
const Bookings = lazy(() => import('./pages/Bookings'));
const Pets = lazy(() => import('./pages/Pets'));

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 700 },
    h2: { fontWeight: 700 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 500 },
    h6: { fontWeight: 500 },
    button: { fontWeight: 700 },
    subtitle1: { fontWeight: 600 },
    subtitle2: { fontWeight: 500 },
  },
});

const App: React.FC = () => {
  const [currentDevice, setCurrentDevice] = useState('desktop');

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <CurrencyProvider>
              <DeviceContext.Provider value={currentDevice}>
                <Router>
                  <ErrorBoundary>
                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative' }}>
                      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Header />
                      </AppBar>
                      <NotificationSystem />
                      <Box component="main" sx={{ flexGrow: 1, paddingTop: '64px' }}> {/* Добавьте отступ, равный высоте хедера */}
                        <Suspense fallback={<LoadingFallback />}>
                          <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/search" element={<SearchForm onSearch={() => {}} />} />
                            <Route path="/flights" element={<FlightListing />} />
                            <Route path="/flight-details" element={<DetailedFlightView />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                            <Route path="/landing" element={<Landing />} />
                            <Route path="/simple-flight-search" element={<SimpleFlightSearch />} />
                            <Route path="/rules" element={<Rules />} />
                            <Route path="/adaptive-landing" element={<AdaptiveLanding userId="anonymous" />} />
                            <Route path="/serp-flight-search" element={<SerpApiFlightSearch />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/bookings" element={<Bookings />} />
                            <Route path="/pets" element={<Pets />} />
                          </Routes>
                        </Suspense>
                      </Box>
                      <Footer />
                      <BottomNav />
                    </Box>
                  </ErrorBoundary>
                </Router>
              </DeviceContext.Provider>
            </CurrencyProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
